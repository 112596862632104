import { default as abuseReportsh9o9Wb1a7fMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93xlb559BrAwMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexvbYPXaHjALMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/index.vue?macro=true";
import { default as moderationcZ4r6yHGqoMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/moderation.vue?macro=true";
import { default as pendingBansYaYf4A0tjPMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/pendingBans.vue?macro=true";
import { default as profilesi2WQUTiTeJMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/profiles.vue?macro=true";
import { default as expensesxWbqcQhV3uMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexbNaDDZ6fZcMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewdjZCxWzCe7Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingkCHM6syD8hMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingpi44YmEbbNMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/translations/missing.vue?macro=true";
import { default as usersFAcyCqpEIfMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/admin/users.vue?macro=true";
import { default as apiy9hQ70Cvx4Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/api.vue?macro=true";
import { default as _91slug_93eMkIUp7dqaMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/blog/[slug].vue?macro=true";
import { default as indexyAucciuoz5Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93X3df3iQB2qMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93p79rsqeM8XMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminEpPS1NI4VbMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/census/admin.vue?macro=true";
import { default as indexVhyVP43tVgMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/census/index.vue?macro=true";
import { default as contactSm3DpQI5SiMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/contact.vue?macro=true";
import { default as designWURxwg4A1YMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/design.vue?macro=true";
import { default as englishUTI4bMYZcuMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/english.vue?macro=true";
import { default as faq1FurBirHsVMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/faq.vue?macro=true";
import { default as inclusiveH6lAh0wYNUMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/inclusive.vue?macro=true";
import { default as indexOes2HC0xrpMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/index.vue?macro=true";
import { default as license3KxPcmWCC4Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/license.vue?macro=true";
import { default as academicNkfCoPkgAmMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/links/academic.vue?macro=true";
import { default as index9PxtmvFiHgMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/links/index.vue?macro=true";
import { default as mediamDbovrr4gLMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/links/media.vue?macro=true";
import { default as translinguistics8FhlGfYMMUMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/links/translinguistics.vue?macro=true";
import { default as zineQrdeAKr3vCMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/links/zine.vue?macro=true";
import { default as namesJ5Qk9coiraMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/names.vue?macro=true";
import { default as indexpdXeggGYk4Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/nouns/index.vue?macro=true";
import { default as templates17E5XgiH3iMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/nouns/templates.vue?macro=true";
import { default as peopleYvxwvkxO4EMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/people.vue?macro=true";
import { default as privacyZU9279F9TpMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/privacy.vue?macro=true";
import { default as _91username_93SIzkjZUy9eMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93QthdEdHwYRMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/profile/card-[username].vue?macro=true";
import { default as editorRj7l6VxAvRMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/profile/editor.vue?macro=true";
import { default as anyL3uMOMuFz3Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/any.vue?macro=true";
import { default as askUsbOHM6adlMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/ask.vue?macro=true";
import { default as avoidingWiCj2xbFlFMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/avoiding.vue?macro=true";
import { default as indexjVLUu8aFxPMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/index.vue?macro=true";
import { default as mirrorAddS4ND1v9Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/mirror.vue?macro=true";
import { default as pronounSu6eNNrFbdMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/pronoun.vue?macro=true";
import { default as sources4os4uQqH6CMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/sources.vue?macro=true";
import { default as teamset0tNdUJAMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/team.vue?macro=true";
import { default as terminologybTdr7e62pMMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/terminology.vue?macro=true";
import { default as terms8GWKQOnTHjMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/terms.vue?macro=true";
import { default as usereVZIOXBDv1Meta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/user.vue?macro=true";
import { default as workshops8jWp5D1DWeMeta } from "/home/admin/www/pronomen.net/release/20241214174959/pages/workshops.vue?macro=true";
import { default as deklinationsmusterHoOdm86sCDMeta } from "~/data/nouns/deklinationsmuster.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsh9o9Wb1a7fMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansYaYf4A0tjPMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiy9hQ70Cvx4Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93eMkIUp7dqaMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexyAucciuoz5Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93X3df3iQB2qMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93p79rsqeM8XMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminEpPS1NI4VbMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexVhyVP43tVgMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactSm3DpQI5SiMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishUTI4bMYZcuMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq1FurBirHsVMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveH6lAh0wYNUMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicNkfCoPkgAmMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index9PxtmvFiHgMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediamDbovrr4gLMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics8FhlGfYMMUMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineQrdeAKr3vCMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesJ5Qk9coiraMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexpdXeggGYk4Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates17E5XgiH3iMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleYvxwvkxO4EMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyZU9279F9TpMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93SIzkjZUy9eMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93QthdEdHwYRMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorRj7l6VxAvRMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyL3uMOMuFz3Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askUsbOHM6adlMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingWiCj2xbFlFMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexjVLUu8aFxPMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorAddS4ND1v9Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounSu6eNNrFbdMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources4os4uQqH6CMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamset0tNdUJAMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologybTdr7e62pMMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms8GWKQOnTHjMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: usereVZIOXBDv1Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops8jWp5D1DWeMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241214174959/pages/workshops.vue")
  },
  {
    name: "nouns-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("~/data/nouns/deklinationsmuster.vue")
  }
]